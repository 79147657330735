<template>
  <div class="admin-container">
    <v-row class="justify-space-between">
      <v-col cols="12" md="4">
        <h1>Zprávy</h1>
      </v-col>
    </v-row>
    <v-row v-for="message in data" :key="message.conversation_id">
      <v-col cols="12" md="6">
        <v-card
          tile
          color="primary"
          dark
          style="cursor: pointer"
          :to="{
            name: 'MessagesDetail',
            params: { id: message.conversation_id },
          }"
        >
          <v-card-title>
            <div
              class="not-readed-message"
              v-if="message.sent_by == 'OTHER' && !message.is_readed"
            >
              1
            </div>
            {{ message.sender.full_name }}
            <div class="message-time">{{ message.created_at }}</div>
          </v-card-title>
          <v-card-subtitle>{{ message.sender.role }}</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col>
                <span class="message-content">
                  {{ message.content }}
                </span>
              </v-col>
            </v-row></v-card-text
          >
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import bus from "../plugins/bus";
import authHandler from "../api/authHandler";
import messageService from "../api/messageService";

export default {
  async created() {
    await this.index();
  },
  data() {
    return {
      data: [],
    };
  },
  methods: {
    async index() {
      try {
        bus.$emit("processing");
        const data = await messageService.index();
        this.data = data;
        bus.$emit("processing", false);
        await bus.$emit("refresh_sections");
      } catch (error) {
        // Handle error
        if (!authHandler(error)) this.$router.push({ name: "Login" });
        bus.$emit("processing", false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit("snackbar", {
            text: error.response.data.error.message,
            color: "error",
          });
          this.$router.push({ name: "Dashboard" });
        }
      }
    },
  },
  watch: {},
};
</script>
